.testimonials {
    display: flex;
    gap: 1rem;
    padding: 0 2em;
}
.left-t{
    color: #fff;
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 2rem;
    text-transform: uppercase;
}
.left-t>:first-child {
    color: var(--orange);
    font-weight: 700;
}
.left-t>:nth-child(2), .left-t>:nth-child(3) {
    font-size: 3rem;
    font-weight: 700;
}
.left-t>:nth-child(4) {
    letter-spacing: 2px;
    line-height: 40px;
    text-align: justify;
    text-transform: none;
}

.right-t {
    flex: 1;
    position: relative;
}

.right-t >img{
    position: absolute;
    width:17em;
    height: 20em;
    object-fit: cover;
    right: 8em;
    top: 2em;

}

.right-t>:nth-child(1){
    position: absolute;
    width: 17em;
    height: 20em;
    border: 2px solid orange;
    background: transparent;
    right:9em;
    top:0.9em;
}
.right-t>:nth-child(2){
    position: absolute;
    width: 17em;
    height: 20em;
    background: var(--planCard);
    top:3em;
    right: 7em;
}

.arrows{
    display: flex;
    gap:1em;
    position: absolute;
    bottom: 1em;
    left: 3em;
}
.arrows>img{
    width: 1.5em;
    cursor: pointer;
}

@media(max-width:992px){
    .left-t>:nth-child(2), .left-t>:nth-child(3) {
        font-size: xx-large;
    }
    .right-t >img {
        right: 1em;
    }
    .right-t>:nth-child(1){
        right:2em;
    }
    .right-t>:nth-child(2){
        right: 0;
    }
    .arrows {
        top: 27em;
        left: 0em;
        bottom: unset;

    }
    
}
@media(max-width:768px){
    .testimonials {
        flex-direction: column;
    }
    .left-t>:nth-child(2), .left-t>:nth-child(3) {
        font-size: xx-large;
    }
    .right-t>img {
        align-self: center;
        position: relative;
        left: 1em;
        top: 1em;
    }
    .right-t>:nth-child(1){
        left:0em;
        top: 0em;
    }
    .right-t>:nth-child(2){
        left: 2em;
        top: 2em;
    }
    .arrows {
        top: -2.5em;
        right: 9em;
        left: unset;
        bottom: unset;
    }


}
@media(max-width:575px){
    .testimonials{
        padding: 0 1em;
    }
    .left-t{
        gap:1em
    }
    .left-t>:nth-child(4) {
        line-height: 30px;
    }
    .arrows {
        top: -2.5em;
        right: 0em;
        left: unset;
        bottom: unset;
    }
}
@media(max-width:360px){
    .left-t{
        gap: 0.5em;
    }
    .right-t >img {
        width: 12em;
        height: 15em;
        top: 4em;
    }
    .right-t>:nth-child(1) {
        left: 0em;
        top: 3em;
        width: 12em;
        height: 15em;
    }
    .right-t>:nth-child(2) {
        left: 2em;
        top: 5em;
        width: 12em;
        height: 15em;
    }
    .arrows {
        top: 0em;
        right: 0px;
    }

}