.Reasons {
    display: flex;
    gap: 2rem;
    padding: 0 2rem;
}
.left-r {
    grid-gap: 1rem;
    display: grid;
    flex: 1 1;
    gap: 1rem;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(3,1fr);
}
.right-r {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
}
.left-r>:first-child {
    grid-row: 1/3;
    height: 28rem;
    width: 12rem;
}
.left-r>:nth-child(2) {
    grid-column: 2/4;
    height: 16rem;
    width: auto;
}
.left-r>:nth-child(3) {
    grid-column: 2/3;
    grid-row: 2;
    width: 14rem;
}
.left-r>:nth-child(4) {
    grid-column: 3/4;
    grid-row: 2;
    height: 11.2rem;
    width: 10rem;
}

.right-r>span {
    color: var(--orange);
    font-weight: 700;
}
.right-r>div {
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
}
.details-r {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.details-r>div>img {
    height: 2rem;
    width: 2rem;
}
.details-r>div {
    display: flex;
    font-size: 1rem;
    gap: 1rem;
}
.partners {
    display: flex;
    gap: 1rem;
}
.partners>img {
    width: 2.5rem;
}
.partner_head{
    color: var(--gray);
    font-weight: normal;
    font-size: 25px;
}

@media (max-width:1140px) {
    .right-r>div{
        font-size: 2.5em;
    } 
}
@media(max-width:992px){
    .Reasons{
        flex-direction: column;
    }
    .right-r{
        margin-bottom: 4em;
    }
    .left-r>:first-child {
        grid-row: 1/3;
        height: 33rem;
        width: 100%;
    }
    .left-r>:nth-child(2) {
        grid-column: 2/4;
        height: 19rem;
        width: 100%;
    }
    .left-r>:nth-child(3) {
        grid-column: 2/3;
        grid-row: 2;
        width: 100%;
        height: 13em;
    }
    .left-r>:nth-child(4) {
        grid-column: 3/4;
        grid-row: 2;
        height: 13em;
        width: 100%;
    }
    
}
@media(max-width:768px){
    .left-r>:first-child {
        grid-row: 1/2;
        height: 19rem;
        width: 100%;
    }
    .left-r>:nth-child(3) {
        grid-column: 1/4;
        grid-row: 2;
        width: 100%;
        height: 19em;
    }
    .left-r>:nth-child(4) {
        grid-column: 1/4;
        grid-row: 3;
        height: 19em;
        width: 100%;
    }
}
@media(max-width:575px){
    .Reasons{
        padding: 0 1em;
    }
}
@media(max-width:360px) {
    .left-r{
        grid-auto-rows: 0fr;
    }
    .left-r>:first-child {
        grid-row: 1;
        grid-column: 1/4;
        height: 20rem;
        width: 100%;
    }
    .left-r>:nth-child(2) {
        grid-column: 1/4;
        grid-row: 2;
        width: 100%;
        height: 13rem;
    }
    .left-r>:nth-child(3) {
        grid-column: 1/4;
        grid-row: 3;
        width: 100%;
        height: 12em;
    }
    .left-r>:nth-child(4) {
        grid-column: 1/4;
        grid-row: 4;
        width: 100%;
        height: 12em;
    }
}