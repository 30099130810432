.Programs {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 2rem;
}

.programs-header {
    color: #fff;
    display: flex;
    font-size: 3rem;
    font-style: italic;
    font-weight: 700;
    gap: 5rem;
    justify-content: center;
    text-transform: uppercase;
}

.prgoram-categories {
    display: flex;
    gap: 1rem;
}
.category{
    display: flex;
    flex-direction: column;
    background: grey;
    padding: 2em;
    gap:1em;
    color: #fff;
    justify-content: space-between;
} 

.category >:nth-child(1){
    width: 2em;
    height: 2em;
    fill: #fff;
}
.category >:nth-child(2){
    font-size: 1em;
    font-weight: bold;
}
.category >:nth-child(3){
    font-size: 0.9em;
    line-height: 25px;
}
.join-now{
    display: flex;
    align-items: center;
    gap: 2rem;
}
.join-now > img{
    width: 1em;
}
.category:hover{
    background: var(--planCard);
    cursor: pointer;
}
@media(max-width:992px){
    .programs-header{
        font-size: 1.6rem;
    }
    .prgoram-categories{
        flex-wrap: wrap;
    }
    .category{
        width: 49%;
    }
    
}
@media(max-width:863px){
    .prgoram-categories{
        gap:1.5em
    }
    .category{
        width:48%
    }
}

@media(max-width:768px){
    .programs-header{
        font-size: 3.1em;
        flex-wrap: wrap;
        gap: 0.5em;
    }
}
@media(max-width:665px){
    .prgoram-categories{
        gap:1em;
    }
}
@media(max-width:575px){
    .Programs{
        padding: 0 1em;
    }
    .programs-header{
        font-size:2.4em;
    }
    .prgoram-categories{
        gap:1em;
    }
    .category{
        width:100%
    }
}
@media(max-width:355px){
    .programs-header {
        font-size: 1.7em;
    }
}