.Footer-container {
    position: relative;
}
.Footer-container>hr {
    border: 1px solid var(--lightgray);
}
.footer{
    padding: 1em 2em;
    display: flex;
    flex-direction: column;
    gap: 4em;
    align-items: center;
    justify-content: center;
    height: 20em;
}
.social-links{
    display: flex;
    gap: 4em;
}
.social-links > img{
  width: 2em;
  height: 2em;
  cursor: pointer;  
}

.logo-f > img{
    width: 10em;
}

.blur-f-1{
    bottom: 0;
    right:15%;
    width:26em;
    height: 12em;
    filter: blur(200px);
    background: red;
}
.blur-f-2{
    bottom: 0;
    left:15%;
    width:26em;
    height: 12em;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}

@media(max-width:992px){

}
@media(max-width:768px){
    
}
@media(max-width:575px){
    footer{
        padding: 0 1em;
    }
    .social-links{
        gap:2em;
    }
}