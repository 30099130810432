.hero{
    display: flex;
    flex-wrap: wrap;
    /* padding: 0 2.5em; */
}
.left-h{
    padding-top:1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-left:2.5em;
    padding-right: 2.5em;
}
.right-h{
    padding-top:1.5rem;
    position: relative;
    background:var(--orange);
    padding-right:2.5em;

}
.the-best-ad{
    align-items: center;
    background-color: #393d42;
    border-radius: 4rem;
    color: #fff;
    display: flex;
    justify-content: flex-start;
    margin-top: 4rem;
    padding: 20px 13px;
    position: relative;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.the-best-ad>div {
    background-color: #f48916;
    border-radius: 3rem;
    height: 80%;
    left: 8px;
    position: absolute;
    width: 5.4rem;
    z-index: 1;
}
.the-best-ad>span {
    z-index: 2;
}
.hero-text {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 4.5rem;
    font-weight: 700;
    gap: 1.5rem;
    text-overflow: inherit;
    text-transform: uppercase;
}
.hero-text>div:nth-of-type(3) {
    font-size: 1rem;
    font-weight: 200;
    letter-spacing: 1px;
    text-transform: none;
    width: 80%;
}
.figures{
    display: flex;
    gap: 2rem;
}
.figures>div {
    display: flex;
    flex-direction: column;
}
.figures>div>span:first-of-type {
    color: #fff;
    font-size: 2rem;
}
.figures>div>span:nth-of-type(2) {
    color: var(--gray);
    text-transform: uppercase;
}
.hero-buttons{
    display: flex;
    font-weight: 400!important;
    gap: 1rem;
}
.hero-buttons>:first-child {
    background-color: var(--orange);
    color: #fff;
    height: 40px;
    width: 8rem;
    border-color: var(--orange);
    border: 1px solid var(--orange);
}
.hero-buttons>:nth-child(2) {
    background-color: initial;
    border: 2px solid var(--orange);
    color: #fff;
    height: 40px;
    width: 8rem;
}
.right-h>.btn {
    color: #000;
    position: absolute;
    right: 3em;
    top: 1em;
}
.btn-joinnow a{
    color:#000;
    text-decoration: none;
}
.btn-joinnow:hover{
    background: #fff !important;
}

.heart-rate {
    align-items: start;
    background-color: var(--darkGrey);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    position: absolute;
    right: 4rem;
    top: 7rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.small_device{
    display: none;
}
.heart-rate>:nth-child(2) {
    color: var(--gray);
}
.heart-rate>:nth-child(3) {
    color: #fff;
    font-size: 1.5rem;
}
.hero-image {
    position: absolute;
    right: 8rem;
    top: 10rem;
    width: 23rem;
    z-index: 1;
}
.hero-image-back {
    position: absolute;
    right: 20rem;
    top: 4rem;
    width: 15rem;
    z-index: 0;
}
.calories {
    background-color: var(--caloryCard);
    border-radius: 5px;
    display: flex;
    gap: 2rem;
    padding: 1rem;
    position: absolute;
    right: 28rem;
    top: 32rem;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.calories>img {
    width: 3rem;
}
.calories>div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories>div>:first-child {
    color: var(--gray);
}
.calories>div>:nth-child(2) {
    color: #fff;
    font-size: 1.5rem;
}
.hero-blur{
    width: 22em;
    height: 30em;
    left: 0;
}

/*responsive code*/
@media(max-width:1115px){
    .hero-text{
        font-size:3.5em;
    }
}
@media(max-width:1200px) {
    .right-h {
        height: 35em;
        padding-right: 0;
        display: flex;
        align-items: center;
    }
    .hero-buttons{
        margin-bottom: 1.5em;
    }
    .hero-image {
        position: absolute;
        width: 20em;
        top: 3rem;
    }
    .hero-image-back{
        position: absolute;
        width: 17em;
        /* right: -21em !important; */
        top: 1em;
    }
    .heart-rate{
        display: none;
    }
    .small_device{
        display: flex;
        /* left: 4em;
        top: 12em; */
        position: relative;
        left: 4em;
        top: 0em;
        flex-direction: row;
        align-items: center;
        background-color: var(--caloryCard);
        z-index: 1;
    }
    .small_device > div{
        display: flex;
        flex-direction: column;
        gap: 1em;
    }
    .small_device > div >span:nth-child(2){
        color: #fff;
        font-size: 1.5rem;
    }
    .right-h>.btn{
        position: relative;
    }
    .calories{
        position: absolute;
        left: 4em!important;
        top: 24em;
        width: max-content;
    }
}
@media(max-width:992px){
    .hero-image{
        right: 2em;
    }
    .hero-image-back {
        position: absolute;
        width: 18em;
        top: 0em;
        right: 8em !important;
    }
}
@media(max-width:768px){
    .hero-image {
        position: absolute;
        width: 17em;
        top: 6rem;
        right: 0em !important;
    }
    .hero-image-back {
        position: absolute;
        width: 17em;
        top: 1em;
        right: 4em !important;
    }
}

@media(max-width:575px){
    .left-h{
        padding-left: 1em;
        padding-right: 1em;
    }
    .hero-blur{
        width: 14em;
        height: 20em;
        left: 0;
        top: 8em;
    }
    
    .right-h {
        height: 38em;
        padding: 0;
    }
    .hero-image {
        position: absolute;
        width: 460px;
        height: 500px;
        right: 0em !important;
        top: 9em;
    }
    .hero-image-back {
        position: absolute;
        width: 17em;
        top: 8em;
        right: 15em !important;
    }
    .small_device{
        position: absolute;
        left: 1em;
        top: 1em;
        width: max-content;
        padding: 12px 1em;
    }
    /* .small_device {
        left: 1em;
        top: 1em;
    } */
    .calories {
        right: 1em !important;
        top: 1em;
        left: unset !important;
    }
}
@media(max-width:420px){
    .hero-text {
        font-size: 2.5em;
    }
    .figures {
        gap: 2rem;
        flex-wrap: wrap;
    }
    .the-best-ad>span {
        font-size: 13px;
    }
}

@media(max-width:465px){
    .small_device{
        left: 0em;
        top: 1em;
        margin: 0 auto;
    }
    .calories {
        top: 9em;
        left: -1em !important;
        margin: 0 auto;
    }
    .hero-image {
        width: 360px;
        height: 400px;
        right: 0em !important;
        top: 16em;
    }
    .hero-image-back {
        width: 11em;
        top: 19em;
        right: 12em !important;
    }

}
@media(max-width:335px) {
    .hero-image {
        width: 315px;
        height: 380px;
        right: -22px !important;
        top: 16em;
    }
    .hero-image-back {
        width: 10em;
        top: 20em;
        right: 9em !important;
    }

}
@media(max-width:310px) {
    .the-best-ad>span {
        font-size: 11.5px;
    }
    .hero-text{
        gap: 0.5rem
    }
    .hero-text {
        font-size: 2em;
    }
}
@media only screen and (min-width:992px) and (max-width:1270){
    
}