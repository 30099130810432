.plans-container{
    margin-top: -4em;
    padding: 0 2em;
    display: flex;
    flex-direction: column;
    gap: 4em;
    position: relative;
}
/* .programs-header {
    color: #fff;
    display: flex;
    font-size: 3rem;
    font-style: italic;
    font-weight: 700;
    gap: 5rem;
    justify-content: center;
    text-transform: uppercase;
} */
.plans{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
}
.plan{
    background: var(--caloryCard);
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem;
    width: 20em;
}
.plan:nth-child(2){
    background:var(--planCard);
    transform: scale(1.1);
}
.plan>svg {
    fill: var(--orange);
    height: 2rem;
    width: 2rem;
}
.plan>:nth-child(2) {
    font-size: 1rem;
    font-weight: 700;
}
.plan>:nth-child(3) {
    font-size: 3rem;
    font-weight: 700;
}
.plan>:nth-child(5) {
    font-size: 0.8rem;
}
.features {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.feature{
    display: flex;
    align-items: center;
    gap: 1rem;
}

.feature>img {
    width: 1rem;
}
.plan:nth-child(2)>svg{
    fill: #fff;
}
.plan:nth-child(2)>button{
    color: var(--orange);
}

.plans-blur-1{
    width: 32em;
    height: 23em;
    top: 6em;
    left: 0;
}
.plans-blur-2{
    width: 32em;
    height: 23em;
    top: 6em;
    right: 0;
}
.btn-join:hover{
    background: var(--orange) !important;
}
.plan:nth-child(2)>button:hover {
    background: #7e3500 !important;
}


@media(max-width:992px){
 .plans{
    gap: 1.5rem;
 }   
 .plan{
    gap: 1.5rem;
 } 
 .plan>:nth-child(3) {
    font-size: 2rem;
    font-weight: 700;
  }
  .feature{
    font-size: 13px;
  } 
  .btn-join{
    padding: 2px 0;
  }

}
@media(max-width:768px){
    .plans {
        flex-direction: column;
        gap: 3em;
    }
    .plans>:nth-child(2) {
        -webkit-transform: none;
        transform: none;
    }
}
@media(max-width:575px){
    .plans-container{
        padding: 0 1em;
    }
    .plan{
        width: 16em;
    }
}
@media only screen and (min-width:355px) and (max-width:400px){
    .plans-header {
        font-size: 2.1em !important;
    } 
}
