.Join {
    display: flex;
    gap: 10rem;
    padding: 0 2rem;
    align-items: end;
}
.left-j {
    color: #fff;
    font-size: 3rem;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
}

.left-j>hr {
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
    position: absolute;
    width: 10.5rem;
}
/* .left-j>div {
    display: flex;
    gap: 1rem;
} */
.right-j {
    align-items: flex-end;
    display: flex;
    justify-content: center;
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display:flex;
    gap:3em;
    background: gray;
    padding: 1em;
    align-items: center;
}
.email-container>input{
    background:transparent;
    border: none;
    outline: none;
    color: var(--lightgray);
    height: 46px;
}
::placeholder{
    color: var(--lightgray);
}
.btn-j{
    background: var(--orange);
    color: #fff;
    height: 48px;
    padding: 10px 20px;
    width: max-content;
}
@media(max-width:1036px){
    .left-j{
        font-size: 2.5em;
    }
}

@media(max-width:992px){
    .Join{
        flex-direction: column;
        gap: 2em;
        padding: 2em 2em;
    }

}
@media(max-width:768px){
    
}
@media(max-width:575px){
    .right-j {
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .Join{
        padding: 2em 1em;
        margin-top: 2em;
    }
    .left-j {
        font-size: 2em;
    }
    .email-container{
        gap: 1em;
    }
}
@media(max-width:420px){
    .email-container{
        flex-direction: column;
    }
    .email-container>input {
        border-bottom: 1px solid #ccc;
    }

}