.header{
    display:flex;
    justify-content:space-between;
    align-items: center;
}
.logo{
    height: 3em;
    width: 10em;
}
.header-menu{
    list-style: none;
    display: flex;
    /* justify-content: space-between; */
    gap:2em;
    color: #fff;
}
.header-menu li:hover{
    cursor: pointer;
    color: var(--orange);
}
.header_upper{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.join-now_btn_div{
    right: 68px;
    position: absolute;
    z-index: 1;
}
.menu_small_main{
    display: none;
}
@media(max-width:992px){
    .logo {
        height: 2.5em;
        width: 8em;
    }
    .nav_fixed-top{
        position: fixed;
        top: 0px;
        right: 0;
        left: 0;
        background: #6a6a6a;
        padding: 8px 15px;
        z-index: 1030;
    }
    .header-menu{
        display: none;
    }
    .menu_small_main{
        display: block;
        position: absolute;
        right: 15px;
        top: 1em;
    }
    .menu_small_main > img{
        width: 35px;
        height: 28px;
        margin-top: -5px;
    }
    .small_header-menu{
        background: #6a6a6a;
        padding: 15px 15px;
        position: fixed;
        right: 0em;
        top: 56px;
        width: 100%;
        z-index: 3;
        border-top: 1px solid #b1b1b1;
    }
    .small_header-menu li{
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 15px;
        color: #ffffff;
        list-style: none;
    }
    .btn-joinnow{
        font-size: 14px;
        padding: 4px 5px;
    }

}
@media(max-width:310px){
    .logo {
        height: 2.5em;
        width: 6em;
    }
}